<template>
  <div :id="anchorName" class="customers-grid">
    <h2 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h2>
    <v-container class="inner-container inner-container--content">
      <div class="customers-grid__content px-10 px-md-0">
        <div v-for="item in items" :key="item.id" class="customers-grid_item">
          <v-img
            :aspect-ratio="360 / 150"
            :src="getPhotoUrl(item)"
            :alt="photoName(item)"
          ></v-img>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
export default {
  mixins: [dynamicBlock],
  methods: {
    getPhotoUrl(item) {
      return this.$helper.getPhotoUrl(item);
    },
    photoName(item) {
      if (!item) return "";
      return item.name;
    },
  },
};
</script>